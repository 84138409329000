export const PRIMARY = '#000000';
export const SECONDARY = '#818181';

export const GRAY_PRIMARY = '#DEDEDE';
export const GRAY_SECONDARY = '#B4B4B4';
export const GRAY_THIRD = '#6E6E6E';
export const GRAY_LIGHT = '#F8F8F8';
export const WHITE = '#FFFFFF';
export const WHITE_IMPORTANT = '#FFFFFF !important';

export const RED = '#ff0000';
export const ORANGE = '#FE5D26';
export const GREEN = '#46954D';
export const BLACK = '#000000';

// Obermain Colors Scheme
export const OBERMAIN_BLACK = '#000000';
export const OBERMAIN_GOLD = '#DAAA00';
export const OBERMAIN_BORDER = '#D9D8D6';
